import React, { useEffect, useState, useRef } from "react";
// store utils
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// utils
import { useRouter } from "next/router";
// firebase auth
import { signOut } from "firebase/auth";
//
import Image from "next/image";
// @mui
import { useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
  usePopupState,
  bindHover,
  bindFocus,
  bindMenu,
} from "material-ui-popup-state/hooks";

const desktopPages = [
  { title: "Loans", url: "/loans" },
  { title: "Insurance", url: "/insurance" },
  { title: "Buy", url: "/listings" },
  { title: "Sell", url: "/sell" },
  { title: "Pricing", url: "/pricing" },
  { title: "Resources" },
];

const mobilePages = [];

const mobileResourcePages = [
  { title: "Loans", url: "/loans" },
  { title: "Insurance", url: "/insurance" },
  { title: "Buy", url: "/listings" },
  { title: "Sell", url: "/sell" },
  { title: "Pricing", url: "/pricing" },
  { title: "Blog", url: "/blog" },
  { title: "Referral Program", url: "/referral-program" },
];

const loggedInOptions = [
  {
    title: "My Loans",
    url: "/my/loans",
  },
  {
    title: "My Insurance",
    url: "/my/insurance",
  },
  {
    title: "My Listings",
    url: "/my/listings",
  },
  {
    title: "My Calculations",
    url: "/my/calculations",
  },
  {
    title: "My Profile",
    url: "/my/profile",
  },
  {
    title: "Sign out",
  },
];

const mobileNotLoggedInOptions = [
  { title: "Sign up or Log in", url: "/login" },
  ...mobileResourcePages,
];

const mobileLoggedInOptions = [...loggedInOptions, ...mobileResourcePages];

// const notMobileLoggedInOptions = [...notLoggedInOptions, ...mobileResourcePages];
const desktopResourcePages = [
  { title: "Blog", url: "/blog" },
  { title: "Referral Program", url: "/referral-program" },
];

const notLoggedInOptions = [
  { title: "Sign up or Log in", url: "/login" },
  // ...desktopResourcePages,
];

const AppHeader = () => {
  const theme = useTheme();
  const router = useRouter();

  const appBarHeaderRef = useRef(null);

  const resourcesMenuState = usePopupState({
    variant: "menu",
    popupId: "resourcesMenu",
  });

  const profileMenuState = usePopupState({
    variant: "menu",
    popupId: "profileMenu",
  });

  const {
    userProfile,
    setUser,
    user,
    isMobile,
    firebase,
    firebaseAuth,
    userPermissions,
    userRoles,
  } = useStore(AppStore);

  // TODO figure out where this is triggered
  const navbarMenuIsOpen = false;

  const [pages, setPages] = useState([]);
  const [resourcePages, setResourcePages] = useState([]);
  const [rightSideOptions, setRightSideOptions] = useState([]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElNav(null);
    setAnchorElUser(event.currentTarget);
  };

  const handleClickAway = (popUpState) => {
    popUpState.close();
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElUser(null);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (!isMobile) {
      setPages(desktopPages);
      setResourcePages(desktopResourcePages);

      if (user) {
        // if admin, need to add another menu
        // for invited user management

        // isAdmin is legacy, and will be substituted in the future
        // will move toward custom claims that are part of a firebase auth token
        if (
          userRoles.length > 0 &&
          userPermissions.Role &&
          [userRoles[0], userRoles[1]].includes(userPermissions.Role) &&
          ["OM"].includes(userPermissions.Organization)
        ) {
          loggedInOptions.push({
            title: "User Management",
            url: "/my/users",
          });
        }

        setRightSideOptions(loggedInOptions);
      } else {
        setRightSideOptions(notLoggedInOptions);
      }
    } else {
      setPages(mobilePages);
      setResourcePages(mobileResourcePages);

      if (user) {
        // if admin, need to add another menu
        // for invited user management
        if (
          userRoles.length > 0 &&
          userPermissions.Role &&
          [userRoles[0], userRoles[1]].includes(userPermissions.Role)
        ) {
          mobileLoggedInOptions.push({
            title: "User Management",
            url: "/my/users",
          });
        }
        setRightSideOptions(mobileLoggedInOptions);
      } else {
        setRightSideOptions(mobileNotLoggedInOptions);
      }
    }
  }, [user, isMobile, userPermissions, userRoles]);

  const open = Boolean(anchorElUser);

  return (
    <AppBar
      id="app_bar_header"
      sx={{
        zIndex: navbarMenuIsOpen ? 6 : 3,
        width: "100%",
        backgroundColor: theme.palette.primary.contrastText,
      }}
      position="sticky"
      ref={appBarHeaderRef}
    >
      <Container sx={{ height: "4em" }} width={"100%"}>
        <Toolbar disableGutters sx={{ height: "4em" }}>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            title={"OfferMarket Home"}
            sx={{
              marginRight: 2,
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: theme.spacing(5),
              color: theme.palette.primary.main,
              textDecoration: "none !important",
              boxShadow: "none",
              "&:hover": {
                color: theme.palette.primary.dark,
                transform: "scale(1.05)",
                transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
              },
            }}
          >
            {/* {isMobile ? "OM" : "OfferMarket"} */}
            OfferMarket
          </Typography>

          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {pages.map((page, index) => {
              if (page.title === "Resources") {
                return (
                  <Box key={`${page.title}_${index}_button_1`}>
                    <Button
                      id={"resources-menu-button"}
                      aria-controls={
                        resourcesMenuState.isOpen
                          ? "resources-content-menu"
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        resourcesMenuState.isOpen ? "true" : undefined
                      }
                      {...bindHover(resourcesMenuState)}
                      {...bindFocus(resourcesMenuState)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();

                          if (resourcesMenuState.isOpen) {
                            document
                              .getElementById(`resources-menu-item-0`)
                              .focus();
                          } else {
                            resourcesMenuState.open(e);
                            setTimeout(function () {
                              document
                                .getElementById(`resources-menu-item-0`)
                                .focus();
                            }, 100);
                          }
                        }
                      }}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        fontSize: theme.spacing(3),
                        textTransform: "none",
                        [theme.breakpoints.down("md")]: {
                          fontSize: theme.spacing(2),
                        },
                      }}
                    >
                      {page.title}
                    </Button>

                    <HoverMenu
                      {...bindMenu(resourcesMenuState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {resourcePages.map((resourcePage, index) => (
                        <MenuItem
                          id={`resources-menu-item-${index}`}
                          key={`${resourcePage.title}_${index}`}
                          onClick={() => {
                            router.push(resourcePage.url);
                            handleClickAway(resourcesMenuState);
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Escape" ||
                              e.key === "Tab" ||
                              (e.shiftKey && e.key === "Tab")
                            ) {
                              document
                                .getElementById("resources-menu-button")
                                .focus();
                            }
                          }}
                        >
                          <Typography
                            sx={{ fontSize: theme.spacing(3) }}
                            textAlign="center"
                          >
                            {resourcePage.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </HoverMenu>
                  </Box>
                );
              } else {
                return (
                  <Button
                    id={`nav-menu-${page.title}`}
                    key={`${page.title}_${index}_2`}
                    onClick={(e) => {
                      router.push(page.url);
                    }}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      fontSize: theme.spacing(3),
                      textTransform: "none",
                    }}
                  >
                    {page.title}
                  </Button>
                );
              }
            })}
          </Box>

          <Box
            {...bindHover(profileMenuState)}
            {...bindFocus(profileMenuState)}
            sx={{ width: theme.spacing(10), flexGrow: 0, textAlign: "right" }}
          >
            <IconButton
              id={"profile-menu-button"}
              aria-controls={
                profileMenuState.isOpen ? "profile-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={profileMenuState.isOpen ? "true" : undefined}
              type="button"
              aria-label="user profile menu"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  if (profileMenuState.isOpen) {
                    document.getElementById(`profile-menu-item-0`).focus();
                  } else {
                    profileMenuState.open(e);
                    setTimeout(function () {
                      document.getElementById(`profile-menu-item-0`).focus();
                    }, 100);
                  }
                }
              }}
            >
              {userProfile?.PictureURL ? (
                <Avatar
                  alt="Your profile picture"
                  src={userProfile.PictureURL}
                />
              ) : (
                <Avatar alt="Your profile picture">
                  <Image
                    alt="Default profile picture placeholder"
                    layout="fill"
                    src={"/OM_logo_square_small.png"}
                  />
                </Avatar>
              )}
            </IconButton>
          </Box>
        </Toolbar>
        <HoverMenu
          anchorRef={appBarHeaderRef.current}
          anchorOrigin={{
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: isMobile ? "right" : "left",
          }}
          id={"profile-menu"}
          sx={{
            "& ul": {
              padding: `2px 0 !important`,
            },
            "& #profile-menu-item-login.MuiMenuItem-root:hover": {
              bgcolor: theme.palette.success.dark,
            },
            "& #profile-menu-item-logout.MuiMenuItem-root:hover": {
              bgcolor: theme.palette.warning.dark,
            },
          }}
          {...bindMenu(profileMenuState)}
        >
          {rightSideOptions.map((option, index) => {
            if (option.title === "Sign out") {
              return (
                <MenuItem
                  id={`profile-menu-item-0`}
                  divider={isMobile}
                  key={`${option.title}_mobile_${index}`}
                  sx={{
                    // backgroundColor: theme.palette.warning.main,
                    color: theme.palette.warning.main,
                    // margin: theme.spacing(0, 0.5),
                    // borderRadius: theme.spacing(0.5),
                    // "&:hover, :focus": {
                    //   backgroundColor: theme.palette.warning.dark,
                    // },
                  }}
                  onClick={(e) => {
                    (async () => {
                      handleCloseUserMenu(e);
                      setUser(false);
                      await signOut(firebaseAuth);
                      router.push("/");
                      window.location.reload();
                    })();
                    handleClickAway(profileMenuState);
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Escape" ||
                      e.key === "Tab" ||
                      (e.shiftKey && e.key === "Tab")
                    ) {
                      document.getElementById("profile-menu-button").focus();
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.spacing(3),
                    }}
                    textAlign="center"
                  >
                    {option.title}
                  </Typography>
                </MenuItem>
              );
            } else if (option.title === "Sign up or Log in") {
              return (
                <MenuItem
                  id={`profile-menu-item-0`}
                  dense
                  sx={{
                    color: theme.palette.success.main,
                  }}
                  color={"success"}
                  key={`${option.title}_mobile_${index}}`}
                  onClick={(e) => {
                    (async () => {
                      handleCloseUserMenu(e);
                      router.push("/login");
                    })();
                    handleClickAway(profileMenuState);
                  }}
                >
                  <Typography
                    sx={{ fontSize: theme.spacing(3) }}
                    textAlign="center"
                  >
                    {option.title}
                  </Typography>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  divider={
                    isMobile &&
                    (option.title === "Sign up or Log in" ||
                      option.title === "Insurance")
                  }
                  id={`profile-menu-item-${index}`}
                  key={`${option.title}_mobile_${index}`}
                  onClick={() => {
                    router.push(option.url);
                    handleClickAway(profileMenuState);
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Escape" ||
                      e.key === "Tab" ||
                      (e.shiftKey && e.key === "Tab")
                    ) {
                      document.getElementById("profile-menu-button").focus();
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.spacing(3),
                      color: loggedInOptions
                        .map((option) => {
                          return option.title;
                        })
                        .includes(option.title)
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                    }}
                    textAlign="center"
                  >
                    {option.title}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </HoverMenu>
      </Container>
    </AppBar>
  );
};
export default AppHeader;

// {!user ? (
//   !isMobile ? (
//     <Button
//       id={"nav-menu-profile"}
//       variant="contained"
//       color="success"
//       key={"sign_up_or_login"}
//       onClick={(e) => {
//         router.push("/login");
//         handleCloseNavMenu(e);
//       }}
//       sx={{
//         my: 2,
//         color: "white",
//         display: "block",
//         fontSize: theme.spacing(2),
//         textTransform: "none",
//       }}
//     >
//       Sign up or Log in
//     </Button>
//   ) : (
//     <Box sx={{ flexGrow: 1, display: "flex" }}>
//       <IconButton
//         id={`mobile-not-logged-in-menu-button`}
//         size="medium"
//         type="button"
//         aria-label="User menu"
//         aria-controls={
//           profileMenuState.isOpen
//             ? "mobile-not-logged-in-menu"
//             : undefined
//         }
//         aria-haspopup="true"
//         aria-expanded={profileMenuState.isOpen ? "true" : undefined}
//         sx={{ p: 0 }}
//         {...bindHover(profileMenuState)}
//         {...bindFocus(profileMenuState)}
//         onKeyDown={(e) => {
//           if (e.key === "Enter" || e.key === " ") {
//             e.preventDefault();
//             if (profileMenuState.isOpen) {
//               document.getElementById(`profile-menu-item-0`).focus();
//             } else {
//               profileMenuState.open(e);
//               setTimeout(function () {
//                 document
//                   .getElementById(`profile-menu-item-0`)
//                   .focus();
//               }, 100);
//             }
//           }
//         }}
//       >
//         <MenuIcon />
//       </IconButton>
//       {/* <ClickAwayListener onClickAway={profileMenuState.close}> */}
//       <HoverMenu
//         id={"mobile-not-logged-in-menu"}
//         {...bindMenu(profileMenuState)}
//         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
//         transformOrigin={{ vertical: "top", horizontal: "left" }}
//       >
//         {mobileNotLoggedInOptions.map((option, index) => {
//           if (option.title === "Sign out") {
//             return (
//               <MenuItem
//                 id={`profile-menu-item-${index}`}
//                 key={`${option.title}_mobile`}
//                 onClick={(e) => {
//                   (async () => {
//                     handleCloseUserMenu(e);
//                     setUser(false);
//                     await signOut(firebaseAuth);
//                     router.push("/");
//                     window.location.reload();
//                   })();
//                   handleClickAway(profileMenuState);
//                 }}
//                 onKeyDown={(e) => {
//                   if (
//                     e.key === "Escape" ||
//                     e.key === "Tab" ||
//                     (e.shiftKey && e.key === "Tab")
//                   ) {
//                     document
//                       .getElementById(
//                         "mobile-not-logged-in-menu-button"
//                       )
//                       .focus();
//                   }
//                 }}
//               >
//                 <Typography
//                   sx={{ fontSize: theme.spacing(3) }}
//                   textAlign="center"
//                 >
//                   {option.title}
//                 </Typography>
//               </MenuItem>
//             );
//           } else if (option.title === "Sign up or Log in") {
//             return (
//               <MenuItem
//                 id={`profile-menu-item-${index}`}
//                 divider
//                 dense
//                 sx={{
//                   backgroundColor: theme.palette.success.main,
//                   color: theme.palette.primary.contrastText,
//                   margin: theme.spacing(0, 0.5),
//                   borderRadius: theme.spacing(0.5),
//                 }}
//                 color={"success"}
//                 key={`${option.title}_mobile`}
//                 onClick={(e) => {
//                   (async () => {
//                     handleCloseUserMenu(e);
//                     router.push("/login");
//                   })();
//                   handleClickAway(profileMenuState);
//                 }}
//               >
//                 <Typography
//                   sx={{ fontSize: theme.spacing(3) }}
//                   textAlign="center"
//                 >
//                   {option.title}
//                 </Typography>
//               </MenuItem>
//             );
//           } else {
//             return (
//               <MenuItem
//                 id={`profile-menu-item-${index}`}
//                 dense
//                 key={`${option.title}_mobile`}
//                 onClick={(e) => {
//                   router.push(option.url);
//                   handleClickAway(profileMenuState);
//                 }}
//                 onKeyDown={(e) => {
//                   if (
//                     e.key === "Escape" ||
//                     e.key === "Tab" ||
//                     (e.shiftKey && e.key === "Tab")
//                   ) {
//                     document
//                       .getElementById(
//                         "mobile-not-logged-in-menu-button"
//                       )
//                       .focus();
//                   }
//                 }}
//               >
//                 <Typography
//                   sx={{ fontSize: theme.spacing(3) }}
//                   textAlign="center"
//                 >
//                   {option.title}
//                 </Typography>
//               </MenuItem>
//             );
//           }
//         })}
//       </HoverMenu>
//     </Box>
//   )
// ) : (
